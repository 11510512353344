$border-radius: 20px;

.main-container {
  z-index: var(--zi-container);
  //docked to the bottom round corners at the top and shadow
  position: absolute;
  bottom: 0;
  left: 0;
  &.bottom {
    right: 0;
    height: var(--dim-docking-bottom-main-conatiner-height);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  &.left {
    top: 0;
    width: var(--dim-docking-left-main-conatiner-width);
  }
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  padding-top: 5px;

  .main-container-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    padding-inline: 10px;
  }
  .bottom-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
    padding: 10px;
    padding-inline: 10px;
    padding-bottom: 0px;
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;
      overflow-x: scroll;
    }
    .tabs-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 10px;
      overflow: auto;
      padding-inline: 10px;
    }
    .round-interactive {
      &.active {
        svg {
          fill: white;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .bottom-bar {
    .buttons-container {
      gap: 5px !important;
      justify-content: unset !important;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .tabs-container {
    display: -webkit-box !important;
    .tab {
      //gap
      margin-right: 10px;
    }
  }
}
