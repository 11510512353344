.settings-section {
  display: flex;
  align-items: start;
  flex-direction: column;
  .section-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
}
