.weapons-area {
  position: absolute;
  z-index: var(--zi-weapons-area);
  .weapon-apperance {
    position: absolute;
    top: 65px;
    &.weapon-right {
      transform: rotate(310deg) scale(0.5);
      // transform: rotate(310deg) scale(0.5);
      // left: 110px;
    }
    &.weapon-left {
      transform: rotate(50deg) scale(-0.5, 0.5);
      // transform: rotate(50deg) scale(-0.5, 0.5);
      // left: -110px;
    }
  }
}
