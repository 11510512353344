.scroll-selector {
  --inline-space: 19px;
  align-self: center;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: calc(100% - 120px);
  overflow-x: auto;
  padding-inline: 40px;
  margin-inline: var(--inline-space);
  margin-bottom: 5px;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  &::before {
    content: "";
    position: absolute;
    left: var(--inline-space);
    width: 50px;
    top: 0;
    height: 35px;
    background: linear-gradient(
      0.25turn,
      white 30%,
      rgba(255, 255, 255, 0.1) 100%,
      rgba(255, 255, 255, 0.001)
    ); /* transparent keyword is broken in Safari */
  }
  &::after {
    content: "";
    position: absolute;
    right: var(--inline-space);
    width: 50px;
    top: 0;
    height: 35px;
    background: linear-gradient(
      -0.25turn,
      white 30%,
      rgba(255, 255, 255, 0.1) 100%
    ); /* transparent keyword is broken in Safari */
  }
  .scroll-selector-item {
    transition: all 0.3s;
    cursor: pointer;
    padding: 5px;
    font-size: 20px;
    &.selected {
      font-weight: bold;
    }
  }
}
