.top-bar {
  display: flex;
  flex-direction: row;
  gap: 5px;
  .filter-input {
    width: 100%;
    padding: 5px;
    border-radius: 50px;
    font-size: 15px;
  }
}
.items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  overflow-y: auto;
  padding: 10px;
  span.catergory-name {
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding-block: 10px;
  }
}
