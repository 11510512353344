.toggle-button-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 10px;
  justify-content: space-between;
  padding-right: 10px;
  &:hover {
    background-color: #cccccc;
  }
  .toggle-button {
    display: flex;
    border-radius: 50px;
    height: 20px;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
    border: 1.5px solid black;
    .active {
      background-color: black;
    }
    &.active .inner-toggle-button {
      background-color: black;
      border-radius: 50px;
      height: 60%;
      aspect-ratio: 1;

      border: 1.5px solid transparent;
    }
  }
}
