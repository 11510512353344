.top-bar {
  display: flex;
  flex-direction: row;
  gap: 5px;
  .filter-input {
    width: 100%;
    padding: 5px;
    border-radius: 50px;
    font-size: 15px;
  }
}
.weapons-main-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  > div {
    justify-content: space-between;
    &:last-child::before {
      content: "";
      top: 10px;
      bottom: 10px;
      width: 1.5px;
      background-color: black;
      position: absolute;
    }
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .weapon-position-label {
      &::first-letter {
        text-transform: capitalize;
      }
      padding-top: 10px;
    }
    .scroll-box {
      overflow-y: auto;
      display: flex;
      flex: 1;
      padding: 5px;
      flex-wrap: nowrap;
      .weapons-side-container {
        justify-content: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        padding-block: 10px;
      }
    }
  }
}
