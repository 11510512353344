.settings-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // overflow-y: auto;
  padding: 10px;
  #dlc-filter {
    .button-group-button.AbyssalTerrors {
      text-wrap: nowrap;
      color: var(--abyssal-terrors-bg-color) !important;
      &.selected {
        color: white !important;
        background-color: var(--abyssal-terrors-bg-color) !important;
      }
    }
  }
}
