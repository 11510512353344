.toast-notification {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 10px;
  z-index: var(--zi-popup);
  opacity: 0;
  display: flex;
  flex-direction: row;
  transition: all 0.3s;
  align-items: center;
  gap: 10px;
  &.show {
    opacity: 1;
  }
  .toast-button-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    .toast-button {
      border-radius: 10px;
      background-color: darkgray;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: white;
      min-width: 50px;
      padding: 5px;
      height: 25px;
      cursor: pointer;
      &:hover {
        background-color: rgb(156, 156, 156);
      }
    }
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    .toast-button-container {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
  }
}

.round-container {
  cursor: pointer;
  background-color: white;
  border: 1.5px solid black;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 25px;
}

.round-interactive {
  height: 25px;
  cursor: pointer;
  background-color: white;
  border: 1.5px solid black;
  border-radius: 40px;
  padding: 5px;
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  &:hover {
    background-color: #4f4f4f;
    svg {
      fill: white;
    }
  }
  &.active {
    background-color: #000;
  }
}
