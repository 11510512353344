#social-container {
  position: fixed;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  gap: 10;
  &.bottom {
    left: 10px;
    top: 10px;
  }
  &.left {
    right: 10px;
    bottom: 10px;
  }
  * {
    cursor: pointer;
  }
  #discord {
    width: 50px;
    height: 50px;
  }
}
