.button-group-container {
  display: flex;
  flex-direction: row;
  padding: 0px;
  height: auto;
  min-height: 34px;
  .button-group-button {
    transition: all 0.3s;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-inline: 7px;
    &:hover {
      background-color: #ccc;
    }
    &:first-child {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }
    &:last-child {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
    }
    &:not(:last-child) {
      border-right: 1px solid black;
    }
    * {
      max-height: 34px;
    }
    &.selected {
      background-color: #000;
      color: white;
      * {
        fill: white;
      }
    }
  }
}
