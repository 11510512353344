.top-bar {
  display: flex;
  flex-direction: row;
  gap: 5px;
  .filter-input {
    width: 100%;
    padding: 5px;
    border-radius: 50px;
    font-size: 15px;
  }
}
.cosmetic-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-block: 10px;
}
