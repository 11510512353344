.interactive-element-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  position: relative;
  width: 75px;
  height: 85px;
  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    color: #000;
    .base {
      width: 100px;
      height: 100px;
      position: absolute;
      align-self: center;
      z-index: 0;
    }
    .dlc {
      width: 60px;
      height: 60px;
      position: absolute;
      align-self: center;
      z-index: 0;
      padding-bottom: 10px;
      border-radius: 0px;
      opacity: 0.4;
    }
  }
  span {
    height: 100%;
    align-content: center;
    font-size: 12px;
  }
  .interaction-element {
    position: absolute;
    width: 110%;
    height: 110%;
    z-index: -99999999;
    top: -10px;
    border-radius: 10px;
    opacity: 0.5;
    border: 2px solid transparent;
    display: hidden;
  }
  &.selected {
    .interaction-element {
      background-color: rgb(56, 56, 56);
    }

    &:hover .interaction-element {
      display: block;
      background-color: rgb(20, 20, 20);
    }
  }
  &:hover {
    cursor: pointer;
    .interaction-element {
      display: block;
      background-color: darkgray;
    }
  }
}
