:root {
  --zi-shadow: -99999999;
  --zi-legs: -0;
  --zi-camera-overlay: 10000;
  --zi-container: 9999999;
  --zi-banner: 99999998;
  --zi-weapons-area: 99999999;
  --zi-popup: 9999999999;
}

.App {
  text-align: center;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@keyframes squeeze {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 0.95) translate(0px, 10px);
  }
  100% {
    transform: scale(1, 1);
  }
}
.bortato-avatar-container {
  position: absolute;
  width: 400px;
  height: 400px;
  &.bottom {
    left: 50%;
    bottom: calc((100% - var(--dim-docking-bottom-main-conatiner-height)) / 2);
    transform: translate(-50%, -40%);
  }
  &.left {
    top: 50%;
    right: calc((100% - var(--dim-docking-left-main-conatiner-width)) / 2);
    transform: translate(50%, -50%);
  }
  .avatar-shadow {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 30px;
    border-radius: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: var(--zi-shadow);
  }
  .animate-container.animate {
    animation: squeeze 2s infinite ease-in-out;
    width: 100%;
    height: 100%;
  }
  .avatar-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

div.popup {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  z-index: var(--zi-popup);
}
div.popup div.background {
  background-color: #282c34;
  opacity: 0.8;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
div.popup div.container {
  position: relative;
  padding-top: 30px;
  max-width: 80%;
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
